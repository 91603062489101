import React from 'react';
import getAvatarColors from '../getAvatarColors.js';

interface LinearGradientsProps {
    gradients: {
        primaryGradientHex1: string;
        primaryGradientHex2: string;
        primaryGradientHex3?: string;
        secondaryGradientHex1: string;
        secondaryGradientHex2: string;
    };
    avatarId?: string;
}

const LinearGradients = ({ gradients, avatarId }: LinearGradientsProps) => {
    return (
        <defs>
            <linearGradient
                id={`s_paint0_${avatarId}`}
                x1="-413"
                y1="23.9999"
                x2="-413"
                y2="175.056"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={gradients.primaryGradientHex1} />
                <stop offset="1" stopColor={gradients.primaryGradientHex2} />
            </linearGradient>
            <linearGradient
                id={`s_paint1_${avatarId}`}
                x1="-413.475"
                y1="23.528"
                x2="-413.475"
                y2="105.665"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={gradients.secondaryGradientHex1} />
                <stop offset="1" stopColor={gradients.secondaryGradientHex2} />
            </linearGradient>
        </defs>
    );
};

interface DiamondProps {
    color?: string;
    hasGesture?: boolean;
    avatarId?: string;
}

const Diamond = (props: DiamondProps) => {
    const { color = '', avatarId = '', hasGesture = false } = props;
    const gradients = getAvatarColors({ color, type: 'shape' });

    return (
        <g id={`diamond_${avatarId}`}>
            {hasGesture ? (
                <>
                    <path
                        d="M116.844 74.0876C101.761 58.9293 91.5582 51.3501 86.2348 51.3501C80.9115 51.3501 70.7083 58.9293 55.6254 74.0876C42.7605 87.1059 35.3743 96.4907 33.4668 102.242C33.5555 102.465 33.6442 102.71 33.733 102.978C35.951 108.818 43.2485 117.913 55.6254 130.263C70.6639 145.421 80.8671 153 86.2348 153C91.5582 153 101.761 145.421 116.844 130.263C129.221 117.913 136.541 108.818 138.803 102.978C138.892 102.755 138.959 102.532 139.003 102.309C137.184 96.5576 129.798 87.1505 116.844 74.0876Z"
                        fill={`url(#s_paint0_${avatarId})`}
                    />
                    <path
                        d="M116.843 68.7375C101.76 53.5792 91.5572 46 86.2338 46C80.9104 46 70.7073 53.5792 55.6244 68.7375C40.5415 83.985 33 94.2392 33 99.5C33 100.302 33.1553 101.216 33.4658 102.242C35.3733 96.4906 42.7595 87.1058 55.6244 74.0875C70.7073 58.9292 80.9104 51.35 86.2338 51.35C91.5572 51.35 101.76 58.9292 116.843 74.0875C129.797 87.1504 137.183 96.5575 139.002 102.309C139.312 101.239 139.468 100.302 139.468 99.5C139.468 94.2392 131.926 83.985 116.843 68.7375Z"
                        fill={`url(#s_paint1_${avatarId})`}
                    />
                </>
            ) : (
                <>
                    <path
                        d="M143.7 63.6521C122.167 42.2525 107.6 31.5527 100 31.5527C92.4 31.5527 77.8334 42.2525 56.3 63.6521C37.9334 82.0306 27.3884 95.2795 24.665 103.399C24.7917 103.713 24.9184 104.06 25.045 104.437C28.2117 112.682 38.63 125.522 56.3 142.956C77.77 164.356 92.3367 175.056 100 175.056C107.6 175.056 122.167 164.356 143.7 142.956C161.37 125.522 171.82 112.682 175.05 104.437C175.177 104.123 175.272 103.808 175.335 103.493C172.738 95.3739 162.193 82.0935 143.7 63.6521Z"
                        fill={`url(#s_paint0_${avatarId})`}
                    />
                    <path
                        d="M143.7 56.0994C122.167 34.6998 107.6 24 100 24C92.4 24 77.8333 34.6998 56.3 56.0994C34.7667 77.6248 24 92.101 24 99.5279C24 100.661 24.2217 101.951 24.665 103.399C27.3883 95.2795 37.9333 82.0306 56.3 63.6522C77.8333 42.2526 92.4 31.5528 100 31.5528C107.6 31.5528 122.167 42.2526 143.7 63.6522C162.193 82.0936 172.738 95.3739 175.335 103.493C175.778 101.983 176 100.661 176 99.5279C176 92.101 165.233 77.6248 143.7 56.0994Z"
                        fill={`url(#s_paint1_${avatarId})`}
                    />
                </>
            )}
            <LinearGradients avatarId={avatarId} gradients={gradients} />
        </g>
    );
};

export default Diamond;
