import React, { useEffect, useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import cn from 'classnames';
import styles from 'styles/organisms/CustomListSplashScreen.scss';
import Close from '../icons/Close.js';
import Button from '../atoms/Button.js';

interface CustomListSplashScreenProps {
    show?: boolean;
    title: string;
    content: string[];
    ctaText: string;
    ctaOnClick?: () => void;
    onClose?: () => void;
}

export const CustomListSplashScreen: React.FC<CustomListSplashScreenProps> = ({
    show = true,
    title,
    content,
    ctaText,
    ctaOnClick,
    onClose,
}) => {
    const [isVisible, setIsVisible] = useState(show);

    useEffect(() => {
        setIsVisible(show);
    }, [show]);

    const animationsRef = useRef();

    useGSAP(
        () => {
            gsap.from('.content_element', {
                x: '2000',
                stagger: 0.1,
                ease: 'power2.inOut',
                duration: 1.5,
            });
            gsap.from('button', {
                scale: 0,
                ease: 'power4.out',
                duration: 0.8,
                delay: 1.7,
            });
        },
        { scope: animationsRef.current },
    );

    const handleClose = () => {
        onClose?.();
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className={styles.overlay}>
            <IconButton
                id="dialog-close-button"
                aria-label="close"
                onClick={handleClose}
                className={styles.close_button}
            >
                <Close className={styles.close_icon} />
            </IconButton>
            <div className={styles.content_wrapper} ref={animationsRef}>
                <h1 id="h1">{title}</h1>

                <div className={styles.content}>
                    {content.map((text) => (
                        <div
                            key={text}
                            className={cn(['content_element', styles.content_element])}
                        >
                            <div className={styles.dot} />
                            <h2 key={text} className={styles.paragraph}>
                                {text}
                            </h2>
                        </div>
                    ))}
                </div>

                <div className={styles.button_container}>
                    <Button
                        kind="secondary"
                        className={styles.secondary_button}
                        onClick={() => {
                            ctaOnClick?.();
                            handleClose();
                        }}
                    >
                        {ctaText}
                    </Button>
                </div>
            </div>
        </div>
    );
};
