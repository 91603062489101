import React from 'react';
import cn from 'classnames';
import { useDropzone } from 'react-dropzone';
import styles from 'styles/organisms/DropzoneWrapper.scss';

export interface DropzoneWrapperProps {
    onDrop: (selectedFiles: FileList | File[]) => void;
    children: React.ReactNode;
    maxFiles?: number;
    className?: string;
}

export const DropzoneWrapper = ({
    children,
    onDrop,
    maxFiles,
    className,
}: DropzoneWrapperProps) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles,
        noClick: true,
    });

    const showUploadFileOverlay = () => <div className={styles.overlay} />;

    return (
        <div {...getRootProps()} className={cn([styles.container, className])}>
            {isDragActive && showUploadFileOverlay()}
            <input {...getInputProps()} />
            {children}
        </div>
    );
};
