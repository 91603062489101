import React from 'react';
import Fade from '@mui/material/Fade';
import { SvgIconProps } from '@mui/material';
import { AvatarSpecs } from '../../../../types/Participant.js';
import { Gesture } from './Gesture.js';
import { Shape } from './Shape.js';
import { Face } from './Face.js';

export interface AvatarProps extends SvgIconProps {
    // an object describing which features make up an Avatar
    avatarSpecs: AvatarSpecs;

    // each Avatar must have a unique id
    id: string;

    // controls height and width of Avatar
    width?: number;

    // optional css styles
    className?: string;

    // determines whether the base shows
    showBase?: boolean;
}

const Avatar = (props: AvatarProps) => {
    const { avatarSpecs, width = 55, className, id = '', showBase = false } = props;

    if (!avatarSpecs) return null;

    const hasGesture = !!avatarSpecs.gesture;

    const key = `${avatarSpecs.shape}-${avatarSpecs.face}-${hasGesture}`;

    const circle = { x: 100, y: 100, radius: 98, fill: avatarSpecs.bgColor };

    const getEllipseRadiusY = () => {
        const quarterR = circle.radius / 4;

        // round to 2 decimals and return the value
        return Math.round(quarterR * 100) / 100;
    };

    const viewBox = { x: 200, y: showBase ? 200 + getEllipseRadiusY() : 200 };

    return (
        <svg
            id={`Avatar_svg_${id}`}
            className={className}
            width={width}
            height={width}
            viewBox={`0 0 ${viewBox.x} ${viewBox.y}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {avatarSpecs.bgColor && (
                <circle
                    cx={circle.x}
                    cy={circle.y}
                    r={circle.radius}
                    fill={circle.fill}
                />
            )}
            {hasGesture && (
                <g id={`Avatar_${id}`}>
                    <g id={`shape_${id}`}>
                        <Shape
                            shape={avatarSpecs.shape}
                            color={avatarSpecs.shapeColor}
                            avatarId={id}
                            hasGesture
                        />
                    </g>
                    <Fade in key={key}>
                        <g id={`face_${id}`}>
                            <Face face={avatarSpecs.face} avatarId={id} hasGesture />
                        </g>
                    </Fade>
                    <g id={`gesture_${id}`}>
                        <Gesture
                            gesture={avatarSpecs.gesture}
                            color={avatarSpecs.shapeColor}
                            avatarId={id}
                        />
                    </g>
                </g>
            )}
            {!hasGesture && (
                <g id={`Avatar_${id}`}>
                    <g id={`shape_${id}`}>
                        <Shape
                            shape={avatarSpecs.shape}
                            color={avatarSpecs.shapeColor}
                            avatarId={id}
                        />
                    </g>
                    <Fade in key={key}>
                        <g id={`face_${id}`}>
                            <Face face={avatarSpecs.face} avatarId={id} />
                        </g>
                    </Fade>
                </g>
            )}
            {showBase && (
                <ellipse
                    opacity="0.5"
                    cx={circle.x}
                    cy={circle.y * 2}
                    rx={circle.radius - 15}
                    ry={getEllipseRadiusY()}
                    fill={avatarSpecs.bgColor}
                />
            )}
        </svg>
    );
};

export default Avatar;
