import React from 'react';
import getAvatarColors from '../getAvatarColors.js';

interface LinearGradientsProps {
    gradients: {
        primaryGradientHex1: string;
        primaryGradientHex2: string;
        primaryGradientHex3?: string;
        secondaryGradientHex1: string;
        secondaryGradientHex2: string;
    };
    avatarId?: string;
}

const LinearGradients = ({ gradients, avatarId }: LinearGradientsProps) => {
    return (
        <defs>
            <linearGradient
                id={`s_paint0_${avatarId}`}
                x1="-192.5"
                y1="35.0001"
                x2="-192.5"
                y2="164.353"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={gradients.primaryGradientHex1} />
                <stop offset="1" stopColor={gradients.primaryGradientHex2} />
            </linearGradient>
            <linearGradient
                id={`s_paint1_${avatarId}`}
                x1="-192.906"
                y1="34.5958"
                x2="-192.906"
                y2="104.932"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={gradients.secondaryGradientHex1} />
                <stop offset="1" stopColor={gradients.secondaryGradientHex2} />
            </linearGradient>
        </defs>
    );
};

interface RoundedProps {
    color?: string;
    hasGesture?: boolean;
    avatarId?: string;
}

const Rounded = (props: RoundedProps) => {
    const { color = '', avatarId = '', hasGesture = false } = props;
    const gradients = getAvatarColors({ color, type: 'shape' });

    return (
        <g id={`rounded_${avatarId}`}>
            {hasGesture ? (
                <>
                    <path
                        d="M106.953 59.8281C101.135 58.4427 93.3169 57.75 83.5002 57.75C73.6439 57.75 65.8262 58.4427 60.0471 59.8281C53.8325 61.3323 48.9835 63.8063 45.5002 67.25C42.0564 70.7333 39.5825 75.5823 38.0783 81.7969C36.8512 87.0219 36.1783 93.85 36.0596 102.281C36.0596 102.677 36.0596 103.073 36.0596 103.469C36.1783 111.9 36.8512 118.728 38.0783 123.953C39.5825 130.207 42.0564 135.056 45.5002 138.5C48.9835 141.983 53.8325 144.457 60.0471 145.922C65.8262 147.307 73.6439 148 83.5002 148C93.3169 148 101.135 147.307 106.953 145.922C113.207 144.457 118.056 141.983 121.5 138.5C124.984 135.056 127.457 130.207 128.922 123.953C130.149 118.807 130.842 112.078 131 103.766C131 103.647 131 103.548 131 103.469V101.984C130.842 93.6719 130.149 86.9427 128.922 81.7969C127.457 75.5823 124.984 70.7333 121.5 67.25C118.056 63.8063 113.207 61.3323 106.953 59.8281Z"
                        fill={`url(#s_paint0_${avatarId})`}
                    />
                    <path
                        d="M83.5 57.75C93.3167 57.75 101.134 58.4427 106.953 59.8281C113.207 61.3323 118.056 63.8062 121.5 67.25C124.983 70.7333 127.457 75.5823 128.922 81.7969C130.149 86.9427 130.842 93.6719 131 101.984C131 101.509 131 101.015 131 100.5C131 90.6833 130.307 82.8656 128.922 77.0469C127.457 70.8323 124.983 65.9833 121.5 62.5C118.056 59.0563 113.207 56.5823 106.953 55.0781C101.134 53.6927 93.3167 53 83.5 53C73.6437 53 65.826 53.6927 60.0469 55.0781C53.8323 56.5823 48.9833 59.0563 45.5 62.5C42.0563 65.9833 39.5823 70.8323 38.0781 77.0469C36.6927 82.8656 36 90.6833 36 100.5C36 101.094 36.0198 101.688 36.0594 102.281C36.1781 93.85 36.851 87.0219 38.0781 81.7969C39.5823 75.5823 42.0563 70.7333 45.5 67.25C48.9833 63.8062 53.8323 61.3323 60.0469 59.8281C65.826 58.4427 73.6437 57.75 83.5 57.75Z"
                        fill={`url(#s_paint1_${avatarId})`}
                    />
                </>
            ) : (
                <>
                    <path
                        d="M132.094 44.2974C124.131 42.411 113.433 41.4678 99.9998 41.4678C86.5123 41.4678 75.8144 42.411 67.9061 44.2974C59.4019 46.3455 52.7665 49.714 47.9998 54.4031C43.2873 59.146 39.9019 65.7485 37.8436 74.2103C36.1644 81.3247 35.2436 90.622 35.0811 102.102C35.0811 102.641 35.0811 103.18 35.0811 103.719C35.2436 115.199 36.1644 124.496 37.8436 131.611C39.9019 140.127 43.2873 146.729 47.9998 151.418C52.7665 156.161 59.4019 159.53 67.9061 161.524C75.8144 163.41 86.5123 164.353 99.9998 164.353C113.433 164.353 124.131 163.41 132.094 161.524C140.652 159.53 147.287 156.161 152 151.418C156.766 146.729 160.152 140.127 162.156 131.611C163.835 124.604 164.783 115.442 165 104.123C165 103.962 165 103.827 165 103.719V101.698C164.783 90.3795 163.835 81.2169 162.156 74.2103C160.152 65.7485 156.766 59.146 152 54.4031C147.287 49.714 140.652 46.3455 132.094 44.2974Z"
                        fill={`url(#s_paint0_${avatarId})`}
                    />
                    <path
                        d="M100 41.4677C113.433 41.4677 124.131 42.4109 132.094 44.2973C140.652 46.3454 147.287 49.7139 152 54.403C156.767 59.1459 160.152 65.7483 162.156 74.2102C163.835 81.2168 164.783 90.3794 165 101.698C165 101.051 165 100.377 165 99.6766C165 86.3101 164.052 75.6654 162.156 67.7425C160.152 59.2807 156.767 52.6783 152 47.9353C147.287 43.2463 140.652 39.8777 132.094 37.8296C124.131 35.9432 113.433 35 100 35C86.5125 35 75.8146 35.9432 67.9062 37.8296C59.4021 39.8777 52.7667 43.2463 48 47.9353C43.2875 52.6783 39.9021 59.2807 37.8438 67.7425C35.9479 75.6654 35 86.3101 35 99.6766C35 100.485 35.0271 101.294 35.0812 102.102C35.2437 90.6219 36.1646 81.3246 37.8438 74.2102C39.9021 65.7483 43.2875 59.1459 48 54.403C52.7667 49.7139 59.4021 46.3454 67.9062 44.2973C75.8146 42.4109 86.5125 41.4677 100 41.4677Z"
                        fill={`url(#s_paint1_${avatarId})`}
                    />
                </>
            )}
            <LinearGradients avatarId={avatarId} gradients={gradients} />
        </g>
    );
};

export default Rounded;
