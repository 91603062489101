import React, { useState } from 'react';
import cn from 'classnames';
import LinearProgress from '@mui/material/LinearProgress';
import Menu from '@mui/material/Menu';
import styles from 'styles/organisms/CtaButton.scss';
import { Room } from '../../../types/Room.js';
import {
    hasActiveSubscription,
    hasTrialSubscription,
} from '../../../shared/subscriptionStatusHelpers.js';
import { useRemainingTrialDays } from '../hooks/queries/useRemainingTrialDays.js';
import Button from '../atoms/Button.js';
import { useCreateStripeCheckoutSession } from '../hooks/mutations/useCreateStripeCheckoutSession.js';

export type CtaButtonProps = {
    room: Room;
    questionCount: number;
    className?: string;
};

export const CtaButton = ({ room, questionCount, className }: CtaButtonProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { data } = useRemainingTrialDays();

    const createStripeCheckoutSession = useCreateStripeCheckoutSession();

    const handleClickUpgradeButton = () => {
        createStripeCheckoutSession.mutate({
            planType: 'annual',
        });
    };

    const showMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const hideMenu = (e?: React.MouseEvent<HTMLButtonElement>) => {
        e?.stopPropagation();
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const remainingTrialDays = data?.remainingTrialDays ?? 0;
    const totalTrialDays = data?.totalTrialDays;

    const isActive = hasActiveSubscription(room);
    const isTrialing = hasTrialSubscription(room);

    let ctaButtonText = `${remainingTrialDays} day${
        remainingTrialDays === 1 ? '' : 's'
    } left`;
    let progressValue = ((totalTrialDays - remainingTrialDays) / totalTrialDays) * 100;
    let menuHeaderText = `You have ${remainingTrialDays} day${
        remainingTrialDays === 1 ? '' : 's'
    } of Premium access left...`;
    let menuSubHeaderText = '...after which you will lose access to:';

    const questionCountValue = questionCount <= 5 ? questionCount : 5;

    if (!isActive && !isTrialing) {
        ctaButtonText = `${questionCountValue}/5 questions saved`;
        progressValue = (questionCountValue / 5) * 100;
        menuHeaderText = "You're now on the free plan...";
        menuSubHeaderText = "...when you upgrade, you'll gain access to:";
    }

    return (
        <>
            {!isActive && (
                <button
                    className={cn([styles.cta_button, className])}
                    type="button"
                    onClick={showMenu}
                >
                    {ctaButtonText}
                    <LinearProgress
                        className={styles.progress_bar}
                        variant="determinate"
                        value={progressValue}
                    />
                </button>
            )}
            <Menu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                anchorEl={anchorEl}
                open={open}
                onClose={hideMenu}
                className={styles.menu}
            >
                <div className={styles.menu_content_container}>
                    <h3 className={styles.h3}>{menuHeaderText}</h3>
                    <h4 className={styles.h4}>{menuSubHeaderText}</h4>
                    <ul className={styles.ul}>
                        <li
                            className={cn([
                                styles.li,
                                {
                                    [styles.x_bullet]: isTrialing,
                                    [styles.checkmark_bullet]: !isTrialing && !isActive,
                                },
                            ])}
                        >
                            Unlimited question saves
                        </li>
                        <li
                            className={cn([
                                styles.li,
                                {
                                    [styles.x_bullet]: isTrialing,
                                    [styles.checkmark_bullet]: !isTrialing && !isActive,
                                },
                            ])}
                        >
                            Unlimited feedback criteria
                        </li>
                        <li
                            className={cn([
                                styles.li,
                                {
                                    [styles.x_bullet]: isTrialing,
                                    [styles.checkmark_bullet]: !isTrialing && !isActive,
                                },
                            ])}
                        >
                            Open feedback comments
                        </li>
                        <li
                            className={cn([
                                styles.li,
                                {
                                    [styles.x_bullet]: isTrialing,
                                    [styles.checkmark_bullet]: !isTrialing && !isActive,
                                },
                            ])}
                        >
                            Student writing scaffolds
                        </li>
                    </ul>
                    <div className={styles.button_container}>
                        <Button
                            onClick={handleClickUpgradeButton}
                            className={styles.upgrade_button}
                            kind="text"
                        >
                            Upgrade
                        </Button>
                        <Button
                            to="/dashboard/account/plan"
                            className={styles.see_opts_button}
                        >
                            See options
                        </Button>
                    </div>
                    <p className={styles.resources_text}>
                        p.s. - We have{' '}
                        <a
                            className={styles.a}
                            target="_blank"
                            rel="noreferrer"
                            href="https://myshortanswer.com/funding-support/"
                        >
                            resources to help you fund Short Answer
                        </a>
                    </p>
                </div>
            </Menu>
        </>
    );
};
