import React from 'react';
import getAvatarColors from '../getAvatarColors.js';

interface LinearGradientsProps {
    gradients: {
        primaryGradientHex1: string;
        primaryGradientHex2: string;
        primaryGradientHex3?: string;
        secondaryGradientHex1: string;
        secondaryGradientHex2: string;
    };
    avatarId?: string;
}

const LinearGradients = ({ gradients, avatarId }: LinearGradientsProps) => {
    return (
        <defs>
            <linearGradient
                id={`s_paint0_${avatarId}`}
                x1="100.327"
                y1="35"
                x2="100.327"
                y2="165"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={gradients.primaryGradientHex1} />
                <stop offset="1" stopColor={gradients.primaryGradientHex2} />
            </linearGradient>
            <linearGradient
                id={`s_paint1_${avatarId}`}
                x1="99.9183"
                y1="34.5938"
                x2="99.9183"
                y2="105.281"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={gradients.secondaryGradientHex1} />
                <stop offset="1" stopColor={gradients.secondaryGradientHex2} />
            </linearGradient>
        </defs>
    );
};

interface SquareProps {
    color?: string;
    hasGesture?: boolean;
    avatarId?: string;
}

const Square = (props: SquareProps) => {
    const { color = '', avatarId = '', hasGesture = false } = props;
    const gradients = getAvatarColors({ color, type: 'shape' });

    return (
        <g id={`square_${avatarId}`}>
            {hasGesture ? (
                <>
                    <path
                        d="M128 68.625C127.802 60.7083 123.626 56.75 115.472 56.75H45.4688C37.3542 56.75 33.1979 60.7083 33 68.625V135.066C33.1979 143.022 37.3542 147 45.4688 147H115.472C123.626 147 127.802 143.022 128 135.066V68.625Z"
                        fill={`url(#s_paint0_${avatarId})`}
                    />
                    <path
                        d="M128 64.4688C128 56.1562 123.824 52 115.472 52H45.4688C37.1562 52 33 56.1562 33 64.4688V68.625C33.1979 60.7083 37.3542 56.75 45.4688 56.75H115.472C123.626 56.75 127.802 60.7083 128 68.625V64.4688Z"
                        fill={`url(#s_paint1_${avatarId})`}
                    />
                </>
            ) : (
                <>
                    <path
                        d="M165.653 57.75C165.381 46.9167 159.638 41.5 148.423 41.5H52.1482C40.9883 41.5 35.2722 46.9167 35 57.75V148.669C35.2722 159.556 40.9883 165 52.1482 165H148.423C159.638 165 165.381 159.556 165.653 148.669V57.75Z"
                        fill={`url(#s_paint0_${avatarId})`}
                    />
                    <path
                        d="M165.653 52.0625C165.653 40.6875 159.91 35 148.423 35H52.1482C40.7161 35 35 40.6875 35 52.0625V57.75C35.2722 46.9167 40.9883 41.5 52.1482 41.5H148.423C159.638 41.5 165.381 46.9167 165.653 57.75V52.0625Z"
                        fill={`url(#s_paint1_${avatarId})`}
                    />
                </>
            )}
            <LinearGradients avatarId={avatarId} gradients={gradients} />
        </g>
    );
};

export default Square;
