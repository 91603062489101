interface AvatarColorProps {
    // red, blue, purple, orange, pink, green, default gray
    color: string;

    // 'shape' or 'gesture'
    type?: string;
}

const getAvatarColors = (props: AvatarColorProps) => {
    const { color, type = 'shape' } = props;
    const isShape = type === 'shape';

    switch (color) {
        case 'red':
            return {
                primaryGradientHex1: '#E26081',
                primaryGradientHex2: '#D44947',
                secondaryGradientHex1: isShape ? '#F37F98' : '#B04350',
                secondaryGradientHex2: isShape ? '#D74854' : '#D64C50',
            };

        case 'blue':
            return {
                primaryGradientHex1: '#7BAAEB',
                primaryGradientHex2: '#6C75E9',
                secondaryGradientHex1: isShape ? '#6B8EE9' : '#6E7EE9',
                secondaryGradientHex2: isShape ? '#95C2F0' : '#6078C5',
            };

        case 'orange':
            return {
                primaryGradientHex1: '#FFCD59',
                primaryGradientHex2: '#F7A41B',
                secondaryGradientHex1: isShape ? '#FFB43C' : '#F8AB26',
                secondaryGradientHex2: isShape ? '#FFE383' : '#E3A634',
            };

        case 'green':
            return {
                primaryGradientHex1: '#71CF93',
                primaryGradientHex2: '#59B15C',
                secondaryGradientHex1: isShape ? '#69C581' : '#5DB666',
                secondaryGradientHex2: isShape ? '#85DFAF' : '#55A164',
            };

        case 'purple':
            return {
                primaryGradientHex1: '#A875E9',
                primaryGradientHex2: '#905AE5',
                primaryGradientHex3: '#6548B9',
                secondaryGradientHex1: isShape ? '#A471E8' : '#744EC9',
                secondaryGradientHex2: isShape ? '#C28DEF' : '#7A4CC1',
            };

        case 'pink':
            return {
                primaryGradientHex1: '#FF99E7',
                primaryGradientHex2: '#FF72A9',
                secondaryGradientHex1: isShape ? '#FF94E0' : '#FF78B3',
                secondaryGradientHex2: isShape ? '#FFB6EA' : '#D870A9',
            };

        // the default is gray
        default:
            return {
                primaryGradientHex1: isShape ? '#A9A9A9' : '#A9A9A9',
                primaryGradientHex2: isShape ? '#616161' : '#616161',
                secondaryGradientHex1: isShape ? '#A9A9A9' : '#616161',
                secondaryGradientHex2: isShape ? '#616161' : '#616161',
            };
    }
};

export default getAvatarColors;
