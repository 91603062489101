import React from 'react';
import getAvatarColors from '../getAvatarColors.js';

interface LinearGradientsProps {
    gradients: {
        primaryGradientHex1: string;
        primaryGradientHex2: string;
        primaryGradientHex3?: string;
        secondaryGradientHex1: string;
        secondaryGradientHex2: string;
    };
    avatarId?: string;
}

const LinearGradients = ({ gradients, avatarId }: LinearGradientsProps) => {
    return (
        <defs>
            <linearGradient
                id={`s_paint0_${avatarId}`}
                x1="-57.4996"
                y1="29.0001"
                x2="-57.4996"
                y2="170.414"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={gradients.primaryGradientHex1} />
                <stop offset="1" stopColor={gradients.primaryGradientHex2} />
            </linearGradient>
            <linearGradient
                id={`s_paint1_${avatarId}`}
                x1="-57.9375"
                y1="28.5581"
                x2="-57.9375"
                y2="105.452"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={gradients.secondaryGradientHex1} />
                <stop offset="1" stopColor={gradients.secondaryGradientHex2} />
            </linearGradient>
        </defs>
    );
};

interface CircleProps {
    color?: string;
    hasGesture?: boolean;
    avatarId?: string;
}

const Circle = (props: CircleProps) => {
    const { color = '', avatarId = '', hasGesture = false } = props;
    const gradients = getAvatarColors({ color, type: 'shape' });

    return (
        <g id={`circle_${avatarId}`}>
            {hasGesture ? (
                <>
                    <path
                        d="M117.375 69.6255C107.625 59.8755 95.8333 55.0005 82 55.0005C68.1667 55.0005 56.375 59.8755 46.625 69.6255C37.4583 78.7922 32.6042 89.7505 32.0625 102.5C32.6042 115.25 37.4583 126.209 46.625 135.376C56.375 145.126 68.1667 150.001 82 150.001C95.8333 150.001 107.625 145.126 117.375 135.376C126.542 126.209 131.396 115.25 131.938 102.5C131.396 89.7505 126.542 78.7922 117.375 69.6255Z"
                        fill={`url(#s_paint0_${avatarId})`}
                    />
                    <path
                        d="M117.375 64.625C107.625 54.875 95.8333 50 82 50C68.1667 50 56.375 54.875 46.625 64.625C36.875 74.375 32 86.1667 32 100C32 100.833 32.0208 101.667 32.0625 102.5C32.6042 89.75 37.4583 78.7917 46.625 69.625C56.375 59.875 68.1667 55 82 55C95.8333 55 107.625 59.875 117.375 69.625C126.542 78.7917 131.396 89.75 131.938 102.5C131.979 101.667 132 100.833 132 100C132 86.1667 127.125 74.375 117.375 64.625Z"
                        fill={`url(#s_paint1_${avatarId})`}
                    />
                </>
            ) : (
                <>
                    <path
                        d="M149.525 56.7526C135.875 42.9647 119.367 36.0708 100 36.0708C80.6337 36.0708 64.1254 42.9647 50.4754 56.7526C37.6421 69.7156 30.8462 85.2122 30.0879 103.243C30.8462 121.273 37.6421 136.769 50.4754 149.732C64.1254 163.52 80.6337 170.414 100 170.414C119.367 170.414 135.875 163.52 149.525 149.732C162.359 136.769 169.155 121.273 169.913 103.243C169.155 85.2122 162.359 69.7156 149.525 56.7526Z"
                        fill={`url(#s_paint0_${avatarId})`}
                    />
                    <path
                        d="M149.525 49.6818C135.875 35.8939 119.367 29 100 29C80.6333 29 64.125 35.8939 50.475 49.6818C36.825 63.4697 30 80.1448 30 99.7071C30 100.886 30.0292 102.064 30.0875 103.242C30.8458 85.2121 37.6417 69.7155 50.475 56.7525C64.125 42.9646 80.6333 36.0707 100 36.0707C119.367 36.0707 135.875 42.9646 149.525 56.7525C162.358 69.7155 169.154 85.2121 169.912 103.242C169.971 102.064 170 100.886 170 99.7071C170 80.1448 163.175 63.4697 149.525 49.6818Z"
                        fill={`url(#s_paint1_${avatarId})`}
                    />
                </>
            )}
            <LinearGradients avatarId={avatarId} gradients={gradients} />
        </g>
    );
};

export default Circle;
