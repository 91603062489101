import React from 'react';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import styles from 'styles/organisms/DashboardMessage.scss';
import Close from '../icons/Close.js';

interface DashboardMessageProps {
    show: boolean;
    onClose: () => void;
    children: string | React.JSX.Element;
}

const DashboardMessage = (props: DashboardMessageProps) => {
    const { show, children, onClose } = props;

    return (
        <Grow in={show}>
            <div className={styles.container}>
                <div className={styles.popper_content}>
                    <IconButton className={styles.close_button} onClick={onClose}>
                        <Close className={styles.close_icon} />
                    </IconButton>
                    {children}
                </div>
            </div>
        </Grow>
    );
};

export default DashboardMessage;
